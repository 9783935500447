<template>
<Form iframe-id="JotFormIFrame-81014038923147">
    <div class="temp">
        <iframe
            id="JotFormIFrame-81014038923147"
            onload="window.parent.scrollTo(0,0)"
            allowtransparency="true" 
            allowfullscreen="true" 
            allow="geolocation; microphone; camera" 
            src="https://form.jotform.com/81014038923147" 
            frameborder="0" 
            style="width: 1px; min-width: 100%; height:70em; border:none; padding-top:8em;" 
            scrolling="no"
            >
        </iframe>
     </div>   
  
</Form>
</template>
<script>
    import Form from '@/components/Form';
    export default {
        name: 'Subscribe',
        components: {
            Form
        }
    }
</script>

<style lang="scss" scoped>

</style>